@charset "utf-8";
@import '~bulma/bulma';

::-webkit-scrollbar:horizontal {
  display: none;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
  box-shadow: inset 0 0 10px (0 0 0/ 10%);
}

body::-webkit-scrollbar {
  width: 0.35em;
  z-index: 1000;
}
body::-webkit-scrollbar-thumb {
  background-color: #00d1b2;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

body.has-navbar-fixed-top,
html.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #404040;
  --clr-yellow: #fcc812;
  --clr-green: #23c495;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  background-color: #fff;
}

html {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

[_nghost-xds-c188] {
  width: 97% !important;
}

body.theme-pierluigi {
  display: none !important;
  width: 97% !important;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.5;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 900;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}

.hero__slider__img {
  padding-top: 40px;
}

.navbar.is-fixed-top {
  top: 0;
  padding: 10px !important;
}

.nav__menu,
.nav__list,
.nav__item {
  padding-right: 40px;
}

.navbar.is-fixed-bottom,
.navbar.is-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 30;
}

.navbar-brand,
.navbar-tabs {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
}

.navbar-item img {
  max-height: 1.75rem;
}

#logomain:hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🌱</text></svg>")
      16 0,
    auto;
}

a.navbar-item,
.navbar-link {
  color: #4a4a4a;
  display: inline;
  font-size: 18px;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
  margin-right: 20px;
}

svg:not(:root).svg-inline--fa {
  margin: 20px;
}

@media screen and (max-width: 369px), print {
  .columns:not(.is-desktop) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .kontaktowy {
    width: 80%;
    margin-top: 70% !important;
  }
  .section {
    display: flex;
    flex-direction: column !important;
  }
  .kreatorImage {
    width: 128px !important;
    margin: -41px -123px -33px 0 !important;
  }
  .strzalkaImage {
    margin: -7px 23px -83px 203px !important;
  }
  h1 {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 369px), print {
  .columns:not(.is-desktop) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .kontaktowy {
    width: 80%;
    margin-top: 40% !important;
  }
  .section {
    display: flex;
    flex-direction: column !important;
  }
  .kreatorImage {
    width: 128px !important;
    margin: -41px -123px -33px 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .kontaktowy {
    width: 60%;
  }
  .section {
    display: flex;
    flex-direction: column !important;
  }
}

@media screen and (min-width: 1024px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }
  .columns:not(.is-desktop) {
    display: flex;
    flex-direction: row;
  }
  .kontaktowy {
    width: 80%;
    margin-top: 10%;
  }
}

@media screen and (min-width: 1400px) {
  .navbar {
    min-height: 3.25rem;
  }
  .columns:not(.is-desktop) {
    display: flex;
    flex-direction: row;
  }
  .kreatorImage {
    width: 200px !important;
    margin: -98px -86px -35px 0 !important;
  }
}

@media screen and (min-width: 1400px) {
  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }
}

.navbar {
  background-color: #fff;
  min-height: 3.25rem;
  position: relative;
  z-index: 30;
}

@media screen and (min-width: 1400px) {
  .navbar-burger {
    display: none;
  }
}

@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }
}
.mb-5 {
  margin-bottom: 3rem !important;
}

@media screen and (max-width: 1022px) {
  h2.dowiedz {
    font-size: 18px;
  }
  .restH1 {
    margin-left: 0px !important;
  }
  h2 {
    font-size: 18px;
    text-align: center;
  }
  .subheader {
    font-size: 22px;
    margin-left: 0px !important;
  }
  button {
    font-size: 22px;
    color: #fff;
  }
}

@media screen and (max-width: 1022px) {
  .offer {
    width: calc(100% - 30px) !important;
  }
  h2.dowiedz {
    width: calc(100% - 30px) !important;
  }
  h1 {
    font-size: 14px;
  }
  .boxshadow {
    width: calc(100% - 30px) !important;
    margin-left: 0px !important;
  }
  .projektuj {
    top: 100px !important;
    left: 50px !important;
  }
  .swiper-container {
    padding-top: 100px !important;
  }
}

@media screen and (min-width: 576px) {
  .home__container,
  .about__container,
  .app__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .about__data,
  .about__initial,
  .app__data,
  .app__initial,
  .contact__container,
  .contact__initial {
    text-align: initial;
  }

  .about__img,
  .app__img {
    width: 480px;
    order: -1;
  }
  .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background: none;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  .swiper-slide img {
    width: 100%;
    justify-self: center;
  }

  .contact__container {
    grid-template-columns: 1.75fr 1fr;
    align-items: center;
  }
  .contact__button {
    justify-self: center;
  }
  .section {
    display: flex;
    flex-direction: column !important;
  }
  .kontaktowy {
    margin-top: 30% !important;
  }
}

@media screen and (max-width: 576px) {
  .home__container,
  .about__container,
  .app__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .about__data,
  .about__initial,
  .app__data,
  .app__initial,
  .contact__container,
  .contact__initial {
    text-align: initial;
  }

  .about__img,
  .app__img {
    width: 480px;
    order: -1;
  }
  .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background: none;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  .swiper-slide img {
    width: 100%;
    justify-self: center;
  }

  .contact__container {
    grid-template-columns: 1.75fr 1fr;
    align-items: center;
  }
  .contact__button {
    justify-self: center;
  }
  .section {
    display: flex;
    flex-direction: column !important;
  }
  .kontaktowy {
    margin-top: 30% !important;
  }
  .strzalkaImage {
    margin: -7px 23px -83px 203px !important;
  }
}

@media screen and (min-height: 721px) {
  .home__container {
    height: 16400px;
  }
  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 575px) {
  .banner {
    display: inline-table;
  }
}

@media screen and (min-width: 1400px) {
  .navbar {
    min-height: 3.25rem;
  }
}

@media screen and (min-width: 1400px) {
  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: -100%;
    left: 50%;
    width: 50%;
    height: 35%;
    font-size: 1.5rem;
    padding: 5%;
    text-align: center;
    background-color: var(--body-color);
    transition: 0.4s;
    border-radius: 0 0 1rem 1rem;
    z-index: var(--z-fixed);
    display: grid;
    background-color: #23c495;
    opacity: 0.9;
    height: auto;
  }
  .navbar.is-fixed-bottom,
  .navbar.is-fixed-top {
    padding: 42px !important;
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 1380px) {
  .menu__container {
    grid-template-columns: repeat(4, 310px);
    column-gap: 1.5rem;
  }
}

@media screen and (min-width: 960px) {
  .about__container,
  .app__container {
    column-gap: 1.5rem;
  }
}

@media screen and (max-width: 880px) {
  .contact-box {
    grid-template-columns: 2fr;
  }
  .left {
    height: 200px;
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }
  .nav__toggle {
    display: none;
  }

  .change-theme {
    position: initial;
    margin-left: var(--mb-2);
  }

  .home__container {
    height: 100vh;
    justify-items: center;
  }

  .services__container,
  .menu__container {
    margin-top: var(--mb-6);
  }

  .menu__content {
    padding: 1.5rem;
  }
  .menu__img {
    width: 130px;
    border-radius: 50%;
  }

  .app__store {
    margin: 0 var(--mb-1) 0 0;
  }
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 2.5rem;
  }
  .subheader {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 2rem;
    text-align: center;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    line-height: 1;
    margin-left: 0px !important;
  }

  h3,
  h4 {
    line-height: 1;
  }
}

@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
  .subheader {
    margin: 0 auto;
  }
}

.label {
  font-weight: 900;
}

.label_imie {
  font-weight: 900;
  margin-bottom: 0.5em;
}

.label_email {
  font-weight: 900;
  margin-bottom: 0.5em;
}

.label_wiadomosc {
  text-align: left !important;
}

.button.is-primary {
  float: right;
}

.subheader {
  width: 300px;
  border-top: 3px solid #23c495;
  font-size: 24px;
  text-align: left;
  font-weight: 100;
  margin-top: 0px;
  padding-top: 0px;
  padding: 10px;
}

.heroIMG {
  text-align: center;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 100px;
}

.strzalkaImage {
  display: inline;
  margin: -96px 63px -100px 10px;
  padding-bottom: 31px;
}

.formH1 {
  text-align: center;
  color: #23c495 !important;
  font-weight: 400;
  margin-bottom: 0px;
}

.form2H1 {
  text-align: center;
  color: #23c495 !important;
}

.formIMG {
  opacity: 0.8;
  display: inline;
  width: 350px;
  vertical-align: sub;
  margin: -108px -118px -21px -153px;
}

.rest {
  text-align: center;
  margin-left: 10%;
  margin-bottom: 0px !important;
}

.restH1 {
  margin-top: 40px;
  color: #23c495;
  font-weight: 400;
  text-align: left;
  padding: 28px;
  margin: 48px;
}

.restIMG {
  opacity: 0.8;
  display: inline;
  max-width: 20%;
  min-width: 177px;
  vertical-align: sub;
  margin: -122px -49px -41px 47px;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
}

.columns.more_projects {
  box-sizing: border-box;
  width: 50%;
}

.more_projects {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 100px;
  margin: 50px;
}

.projectsH1 {
  color: #23c495;
  font-weight: 400;
  text-align: left;
}

.columns:last-child {
  margin-left: auto;
  margin-right: auto;
}

.button.is-warning {
  border-radius: 15px;
  background-color: #fcc812;
  color: #fafafa;
  font-weight: 900;
  width: 150px;
  height: 50px;
  padding: 13px;
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}
.logo {
  height: 40px;
}
.links a {
  color: var(--clr-grey-3);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
}
.links a:hover {
  background: var(--clr-primary-8);
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}
.social-icons {
  display: none;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}

.main {
  display: inline;
}

.columns:not(:last-child) {
  margin-bottom: 0.75rem;
  margin-right: 0px !important;
}

.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.numer_tel {
  padding: 0.125em;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.header img {
  padding: 15px 0;
  max-height: 5.75rem;
  cursor: pointer;
}

.headerH1 {
  color: #23c495;
  padding: 5rem;
  padding-bottom: 0.5rem;
}

.kreatorH1 {
  color: #23c495;
  font-weight: 400;
}

.kreatorImage {
  display: inline;
  width: 175px;
  vertical-align: sub;
  margin: -41px -99px -38px -45px;
  -webkit-transform: rotate(65deg);
  -moz-transform: rotate(65deg);
  -ms-transform: rotate(65deg);
  -o-transform: rotate(65deg);
  transform: rotate(65deg);
}

.title strong {
  font-weight: 900;
}

a strong {
  color: currentColor;
}

strong {
  color: #23c495;
  font-weight: 700;
}

strong {
  font-weight: bold;
}

.link-green {
  transition-duration: 0.5s;
  color: #133d63;
}

.link-green:hover {
  transition-duration: 0.5s;
  font-weight: 900;
  text-decoration: underline;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptextup {
  visibility: hidden;
  width: 300px;
  background-color: #202020;
  color: #23c495;
  text-align: center;
  padding: 10px 12px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptextup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #202020 transparent transparent transparent;
}

.tooltip:hover .tooltiptextup {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptextdown {
  visibility: hidden;
  width: 300px;
  background-color: #202020;
  color: #23c495;
  text-align: center;
  padding: 10px 12px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptextdown::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #202020 transparent;
}

.tooltip:hover .tooltiptextdown {
  visibility: visible;
  opacity: 1;
}

.navbar-item {
  font-size: 13px;
  color: #222222;
  font-weight: 700;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.navbar-item:hover,
.navbar-item:focus {
  color: #23c495 !important;
}

.navbar-end {
  margin-left: 110px !important;
}

.navbar-burger {
  border: 0px solid white !important;
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: 0 0 0 0em white !important;
}

.navbar-burger span {
  height: 2px !important;
}

.subMenu {
  display: none;
  left: 25%;
  padding: 20px;
  position: fixed;
  top: 72px;
  background-color: #111111;
  z-index: 100;
  border: 0px solid white;
}

#blogsub {
  left: 37%;
}
.subsub {
  text-align: left;
  text-transform: uppercase !important;
  display: none;
  padding-left: 5px;
}

.subadd {
  position: absolute;
  left: 229px;
  background-color: #111111;
  min-width: 333px;
  margin-top: -38px;
  padding: 10px 10px 10px 30px;
}

.submenu-item {
  font-size: 12px;
  font-weight: 700;
  color: #999999;
  padding: 5px;
  display: block;
}

.submenu-item:hover {
  color: #23c495;
}
.fmenu {
  list-style: circle;
}
.fmenu > li {
  margin-left: 45px;
  font-size: 12px !important;
}

.column > .kmain {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.service-icon {
  color: #1d809f;
  width: 35px;
  height: 35px;
  margin: 10px;
  float: left;
  line-height: 7.5rem;
  font-size: 2.25rem;
  margin-right: 0px !important;
}

.content-section-heading {
  text-align: center;
  margin-bottom: 1.5rem;
}

.submenu-item {
  font-size: 12px;
  font-weight: 700;
  color: #999999;
  padding: 5px;
  display: block;
}

.submenu-item:hover {
  color: #23c495;
}

.submenu-item {
  padding: 6px;
}
button {
  display: inline-block;
  float: left;
}

.show-menu {
  top: var(--header-height);
}

.active-link {
  color: var(--first-color);
}

.scrolheader {
  background-color: #fff;
  opacity: 1;
}

.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(6, 156, 84, 0.5);
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  visibility: hidden;
}

.icon-green {
  width: 10px;
  transition-duration: 0.5s;
  margin-right: 15px;
  padding: 0px;
  margin: 3px;
}
.icon-green:hover {
  transition-duration: 0.5s;
  transform: scale(1.3);
  filter: brightness(0) invert(1);
}

.scrolltop:hover {
  background-color: var(--first-color-alt);
}

.scrolltop__icon {
  font-size: 1.8rem;
  color: var(--body-color);
}

.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

.home__container {
  height: calc(100vh - var(--header-height));
  align-content: center;
}

.home__title {
  font-size: var(--biggest-font-size);
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.home__title span {
  color: var(--title-color);
}

.home__subtitle {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.home__paragraph {
  color: var(--title-color);
  padding: 15px 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.swiper-slide {
  width: 200px;
}

.swiper-slide img {
  width: 90%;
  padding-top: 0px;
  justify-self: center;
  top: 0;
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: none;
}

.checkbox {
  cursor: pointer;
  display: inline-block;
  margin-top: 0.675em;
  line-height: 1rem;
  position: relative;
  input {
    cursor: pointer;
  }
  &:hover {
    color: $input-hover-color;
  }
  &[disabled],
  fieldset[disabled] &,
  input[disabled] {
    color: #23c495;
    cursor: not-allowed;
  }
}

.about__data {
  text-align: center;
}

.about__description {
  margin-bottom: var(--mb-3);
}

.about__img {
  width: 380px;
  height: auto;
  border-radius: 0.5rem;
  justify-self: center;
}

.services__container {
  row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.services__content {
  text-align: center;
}

.services__img {
  width: 64px;
  height: 64px;
  fill: var(--first-color);
  margin-bottom: var(--mb-2);
}

.services__title {
  font-size: var(--h3-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.services__description {
  padding: 0 1.5rem;
}

.menu__container {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.menu__content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  border-radius: 0.5rem;
  box-shadow: 10px 5px 5px black;
  padding: 0.75rem;
}

.content-section {
  margin-right: 10%;
}

.menu__img {
  width: 100px;
  align-self: center;
  border-radius: 50%;
  margin-bottom: var(--mb-2);
}

.menu__name,
.menu__preci {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.menu__name {
  font-size: var(--normal-font-size);
}

.menu__detail,
.menu__preci {
  font-size: var(--small-font-size);
}

.menu__detail {
  margin-bottom: var(--mb-1);
}

.menu__button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  padding: 0.625rem 0.813rem;
  border-radius: 0.5rem 0 0.5rem 0;
}

.app__data {
  text-align: center;
}

.app__description {
  margin-bottom: var(--mb-5);
}

.app__stores {
  margin-bottom: var(--mb-4);
}

.app__store {
  width: 120px;
  margin: 0 var(--mb-1);
}

.app__img {
  width: 230px;
  justify-self: center;
}

.contact__container {
  text-align: center;
}

.contact__description {
  margin-bottom: var(--mb-3);
}

.contact-box {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--body-color);
  border-radius: 0.5rem;
  padding: 0px !important;
}

.right {
  padding: 25px 40px;
}

.zamow {
  display: flex;
}

textarea {
  min-height: 150px;
  resize: none;
  text-align: left;
}

::placeholder {
  color: var(--title-color);
  opacity: 1;
}
.btn {
  width: 100%;
  padding: 0.5rem 1rem;
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  border-radius: 0.5rem;
  transition: 0.3s;
  font-size: 1.1rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  background-color: #00744d;
}

.footer {
  background-color: #23c495 !important;
  color: white;
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

#logo-footer {
  transition-duration: 1.5s;
}

#logo-footer:hover {
  transition-duration: 1.5s;
  transform: scale(1.2);
}

.title {
  text-align: center;
  margin-left: 10%;
  margin-bottom: 0px !important;
  margin-top: 5%;
}

.dowiedz {
  text-align: center;
  justify-content: center;
}

.is-success {
  margin-left: 2%;
}

textarea {
  margin: 0 !important;
  padding: 0 !important;
}

.formKontakt {
  text-align: center;
  margin-bottom: 20px;
}

.boxshadow {
  max-width: 600px;
  margin-top: 0px;
  padding-top: 0px;
  padding: 20px;
  margin-left: 20%;
}

.offert {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-top: 2%;
  justify-content: space-around;
}

.offer,
.offer:hover {
  transition-duration: 1.5s;
}

.offer {
  width: 17%;
  border-radius: 15px;
  padding-top: 20px;
  border: 1px solid rgb(35, 196, 149);
  margin: 20px;
  display: inline-block;
  margin: 1% 1% 30px;
  text-align: left;
  padding: 10px;
}

.offp {
  color: #333;
  font-size: 12px;
  margin-bottom: 0px;
  padding: 5px;
}

.button-green,
.button-green-no-zoom {
  color: #23c495;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #23c495;
  padding: 10px;
  border-radius: 20px;
  transition-duration: 1s;
}

.portfolio_link {
  text-align: center;
  padding-top: 50px;
}

.navbar.is-fixed-top {
  top: 0;
}

.navbar.is-fixed-bottom,
.navbar.is-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 30;
  padding: 130px;
  padding-top: 40px;
  padding-bottom: 0px;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
  line-height: 2.5rem;
}

.navbar {
  background-color: #fff;
  min-height: 3.25rem;
  position: relative;
  z-index: 30;
}

.button.is-active,
.button:active {
  border-color: #4a4a4a;
  color: #363636;
}
.button:active,
.button:focus,
.file-cta:active,
.file-cta:focus,
.file-name:active,
.file-name:focus,
.input:active,
.input:focus,
.is-active.button,
.is-active.file-cta,
.is-active.file-name,
.is-active.input,
.is-active.pagination-ellipsis,
.is-active.pagination-link,
.is-active.pagination-next,
.is-active.pagination-previous,
.is-active.textarea,
.is-focused.button,
.is-focused.file-cta,
.is-focused.file-name,
.is-focused.input,
.is-focused.pagination-ellipsis,
.is-focused.pagination-link,
.is-focused.pagination-next,
.is-focused.pagination-previous,
.is-focused.textarea,
.pagination-ellipsis:active,
.pagination-ellipsis:focus,
.pagination-link:active,
.pagination-link:focus,
.pagination-next:active,
.pagination-next:focus,
.pagination-previous:active,
.pagination-previous:focus,
.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.textarea:active,
.textarea:focus {
  outline: none;
}

.navbar-burger {
  border: 0 solid #fff !important;
}

.navbar-burger {
  color: #4a4a4a;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
}

.text-faded {
  display: inline-block;
}

.offert_link {
  margin-left: 0px !important;
}

#contact {
  background-color: #fff;
}

.boxshadow {
  background: rgb(35, 196, 149);
}

.boxshadow,
.ofintro {
  border-radius: 20px;
}

#formularz_kontaktowy {
  text-align: center;
}

.columns:last-child {
  margin-bottom: 2.25rem;
}

.review__main__container {
  margin-top: 20px;
}

.review__user__info {
  text-align: center;
}

.banner {
  width: 100%;
  background-color: #23c495;
  color: #fff;
  padding-top: 2.5rem;
  min-height: 200px;
}

.bannerH1 {
  text-align: center;
  font-weight: 700;
}

.bannerP {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.bannerH4 {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}

.banner2 {
  width: 100%;
  background-color: #23c495;
  color: #fff;
  padding: 60px;
}

.banner2H1 {
  text-align: center;
  font-weight: 700;
}

.banner3 {
  width: 100%;
  min-height: 200px;
  background-color: #23c495;
  color: #fff;
  padding: 40px;
}

.banner3H1 {
  text-align: center;
  font-weight: 700;
  margin: 20px;
}

.banner3P {
  text-align: center;
  font-size: 16px;
}

.banner4 {
  width: 100%;
  background-color: #23c495;
  color: #fff;
  padding-top: 2.5rem;
  min-height: 200px;
}

.banner4H1 {
  text-align: center;
  margin: 20px;
  padding: 20px;
}

.hero__main__container {
  width: 70%;
  height: 75vh;
  background-color: #fff;
  .hero__swiper__container {
    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 1rem;
    }

    .hero__slider__img__container {
      .hero__slider__img {
        width: 40%;
      }
    }

    .hero__content__container {
      justify-content: center;

      h4 {
        font-size: 1.2rem;
        color: #23c495;
        text-transform: capitalize;
      }

      h1 {
        font-size: 2.5rem;
        margin-bottom: 0px;
      }

      p {
        font-size: 1.6rem;
        color: #00744d;
        text-transform: capitalize;
      }
    }
  }
}
.scrolheader {
  z-index: 2;
  clear: both;
  float: left;
  background-color: #fff;
}

html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: none;
  margin: auto;
}

.react-photo-gallery--gallery {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

img.obrazki {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  padding: 5px !important;
}

.obrazki {
  text-align: center;
}

.is-title {
  text-align: 'center';
  margin-bottom: '5px';
  font-size: 13px;
  color: 'rgb(0, 209, 178)';
}

.opis {
  text-align: left;
}

.kontaktowe {
  text-align: left;
  margin-top: 2rem;
  filter: brightness(0) invert(1);
  justify-content: space-evenly;
}

.subtitle {
  text-align: left;
}

.kontaktowy {
  width: 100%;
  margin-top: 30%;
}

.section {
  width: 100%;
}

img.kontaktowe {
  width: 20px;
  padding: 0px;
  padding-right: 5px;
  margin: 0px;
}

.portfolio {
  width: 10px;
  display: inline-block;
}

.icon-sociale {
  width: 25px;
  padding: 0px;
  margin: 5px;
  filter: brightness(0) invert(1);
  transition-duration: 1.5s;
}
.icon-sociale:hover {
  transform: scale(1.2);
  transition-duration: 1.5s;
}

.filizanka {
  display: inline;
}

.koperta {
  margin-right: 0px;
  padding: 0px;
  display: inline;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.projektuj {
  position: absolute;
  top: 200px;
  left: 100px;
  z-index: 10;
}

.obrazek-kreator {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.przyklady {
  width: 100%;
  padding-bottom: 50px;
}

.slajdy {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.green {
  color: #23c495;
  font-weight: 900;
}

.field {
  padding-top: 4%;
}

.hero-item {
  color: hsl(205, 100%, 96%);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #23c495;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #23c495;
}

.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #23c495;
}

.active-link.active {
  color: white !important;
}
@media screen and (min-width: 770px) {
  .active-link.active {
    color: #23c495 !important;
  }
}
#fuse-splash-screen > div > div > div > img.img-logo {
  display: none !important;
}
